<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Service
                        <v-spacer></v-spacer>
                        <add-button @action="form.reset(),form.dialog = true, $refs.form ? $refs.form.reset(): ''"
                                    permission="notice-create">
                            New Service
                        </add-button>
                    </v-card-title>

                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :options.sync="pagination"
                                  :footer-props="footerProps"
                                  :server-items-length="form.items.meta.total">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left"><img :src="item.image" width="15%"></td>
                                <td class="text-xs-left">{{item.order}}</td>
                                <td class="text-xs-left">
                                    <v-chip
                                            class="ma-2"
                                            color="blue"
                                            text-color="white"
                                            v-if="item.status == 1"
                                    >
                                        Yes
                                    </v-chip>
                                    <v-chip
                                            class="ma-2"
                                            color="red"
                                            text-color="white"
                                            v-if="item.status == 0"
                                    >
                                        No
                                    </v-chip>
                                </td>
                                <td class="text-xs-left">{{ item.short_desc }}</td>
                                <td class="text-xs-left">{{ item.description }}</td>
                                <td class="text-xs-right">
                                    <edit-button permission="notice-update"
                                                @agree="form.edit(item),updateRole(item)"/>
                                    <delete-button permission="notice-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" scrolalble persistent max-width="800px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Notice Details</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form class="notice_form" ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs6>
                                        <v-text-field outlined dense label="Title*" autocomplete="off" required class="pa-0"
                                                      v-model="form.title" name="name"
                                                      :error-messages="form.errors.get('title')"
                                                      :rules="[v => !!v || 'Title Field is required']"/>
                                    </v-flex>

                                    <v-flex xs6>
                                        <v-text-field outlined dense label="Order" autocomplete="off" required class="pa-0"
                                                      v-model="form.order" name="order"
                                                      :error-messages="form.errors.get('order')"
                                                      :rules="[v => !!v || 'Order Title Field is required']"/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field outlined dense label="Short Description*" autocomplete="off" required
                                                      class="pa-0"
                                                      v-model="form.short_desc" name="short_desc"
                                                      :error-messages="form.errors.get('short_desc')"
                                                      :rules="[v => !!v || 'Short Description Title Field is required']"/>
                                    </v-flex>
                                    <v-flex xs12 class="mt-0 pt-0">
                                        <v-textarea outlined dense :error-messages="form.errors.get('description')"
                                                    v-model="form.description" name="description" label="Description*"
                                                    hint="Short and sweet description is always eye catching."
                                                    :rules="[v => !!v || 'Description Title Field is required']"/>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-checkbox color="blue" v-model="form.status" label="Status" class="pa-0">
                                        </v-checkbox>
                                    </v-flex>

                                    <v-flex xs12 text-xs-center class="ma-0 pa-0">
                                        <v-scroll-y-transition>
                                            <v-btn outlined @click="onPickFile" v-if="!imagePicker.url">
                                                <v-icon left dark small>add_a_photo</v-icon>
                                                Select Document
                                            </v-btn>
                                        </v-scroll-y-transition>
                                        <!-- <v-text-field label="Select Photo" v-model="imagePicker.name" prepend-inner-icon="add_a_photo" @click="onPickFile" required /> -->
                                        <input type="file" style="display: none" ref="image" accept="image/*"
                                               @change="onFilePicked">
                                    </v-flex>
                                    <v-scroll-y-reverse-transition>
                                        <v-flex xs12 text-xs-center class="ma-0 pa-0" v-if="imagePicker.url"
                                                style="position: relative;">
                                            <v-btn outlined icon color="green" @click="onPickFile">
                                                <v-icon small>compare_arrows</v-icon>
                                            </v-btn>
                                            <v-avatar :size="112" color="grey lighten-4">
                                                <img :src="imagePicker.url" alt="avatar">
                                            </v-avatar>
                                            <v-btn outlined icon color="red" @click="resetImagePicker">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-scroll-y-reverse-transition>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import ImageCompressor from 'image-compressor.js'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    // const year = parseInt(nd.getYear()) + 1;

    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                description: '',
                short_desc: '',
                order: '',
                status: '',
                image: '',
            }, '/api/school-service'),
            search: null,
            selectedRole: [],
            roles: [],
            roleLoading: false,
            pagination: {},
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 80},
                {text: 'Title', align: 'left', value: 'title'},
                {text: 'Image', align: 'left', value: 'role'},
                {text: 'Order', align: 'left', value: 'order', sortable: false},
                {text: 'Status', align: 'left', value: 'status', sortable: false},
                {text: 'Short Description', align: 'left', value: 'short_description', sortable: false},
                {text: 'Description', align: 'left', value: 'description', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
        }),

        computed: {
            ...mapState(['batch'])
        },
        watch: {
            'pagination': function () {
                this.get();
                this.fetchRoles();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },
            fetchRoles() {
                this.roleLoading = true;
                this.$rest.get('/api/roles?rowsPerPage=50&sortBy=name').then(({data}) => {
                    // if (data.data.length) {
                    //     this.roles = data.data;
                    // }
                    let role = [];
                    // role.push({id: 0, slug: 'all', name: 'All'});
                    data.data.map(function (item) {
                        role.push({id: item.id, slug: item.slug, name: item.name});
                    });

                    this.roles = role;

                    // console.log(this.roles);
                }).finally(() => {
                    this.roleLoading = false;
                });
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                if (this.form.status) {
                    this.form.status = 1;
                } else {
                    this.form.status = 0;
                }
                this.form.image = this.imagePicker.image;
                let selectedRoleSlug = [];

                if (this.selectedRole.length === this.roles.length) this.form.role = 'all';
                else {
                    this.selectedRole.map(function (item) {
                        selectedRoleSlug.push(item.slug);
                    });
                    this.form.role = selectedRoleSlug.join(', ');
                }

                this.form.store().then(() => {
                    this.resetImagePicker();
                });
            },

            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName;

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .3})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },


            /**
             * Image Upload Handler
             */
            onPickFile() {
                this.$refs.image.click()
            },

            roleChanged(item) {
                this.selectedRole = this.selectedRole.filter(function (item) {
                    return (typeof item === 'object');
                });
                // document.querySelector('input[role=combobox]').setAttribute('value', 'testing...');
            },
            updateRole(item) {
                if (item.role) {
                    const roles = item.role.split(', ');
                    this.selectedRole = this.roles.filter(function (role) {
                        return (roles.includes(role.slug));
                    });
                }

            }

        }
    }
</script>
<style lang="scss">
    .notice_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }
</style>